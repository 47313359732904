/** @jsx jsx */
import { jsx } from "theme-ui"
import { PageProps } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import Calculator from "components/calculator"
import RelatedContent from "components/related-content"
import { getLocationState, LocationState } from "components/state-link"
import { Location as HLocation } from "history"

const Page = ({ location }: PageProps) => {
  const title = `Ипотечный калькулятор с учетом инфляции`
  const description = `Ипотечный калькулятор рассчитывает кредит с учетом инфляции. Таблица и график ипотечных платежей. Учитываем особенности банков в ${new Date().getFullYear()} г.`
  const locationState = getLocationState((location as any) as HLocation<LocationState>)

  return (
    <Layout locationState={locationState}>
      <SEO title={title} description={description} pathname={location.pathname} />
      <h1 id="calculator">Ипотечный калькулятор с учетом инфляции</h1>
      <div>
        <hr />
      </div>
      <Calculator needInflation location={location} />
      <div id="content" sx={{ mt: 3 }}>
        <hr />
        <h2 id="как-рассчитывается-ипотека-с-учетом-инфляции">Как рассчитывается ипотека с учетом инфляции</h2>
        <p>
          Мы взяли статистику по инфляции за последние годы и приняли в качестве средней ставки инфляции 5% в год. В
          данном ипотечном калькуляторе инфляция учитывается только в расчете переплате. Каждые 12 месяцев переплата
          начинает накопительно уменьшаться на размер инфляции: в первые 11 месяцев переплата неизменна, с 12 по 23
          месяц переплата ежемесячно уменьшается на процент инфляции, с 24 по 35 месяца переплата дважды уменьшается на
          инфляцию и так далее.
        </p>
        <h2 id="зачем-считать-переплату-с-учетом-инфляции">Зачем считать переплату с учетом инфляции</h2>
        <p>
          Один из частых аргументов в пользу ипотеку это тот факт, что с каждым месяцем и годом платить ипотечный кредит
          все легче и легче благодаря инфляции валюты. Наш калькулятор рассчитывает насколько конкретно легче, вычисляя
          скорректированную на инфляцию переплату.
        </p>
        <h2 id="важная-деталь">Важная деталь</h2>
        Считается, что ипотека выгодна, так как есть инфляция. Важно понимать, что выгода от инфляции может быть только,
        если ваши доходы (зарплата и др.) растут не медленнее инфляции.
      </div>
      <RelatedContent locationState={locationState} />
    </Layout>
  )
}

export default Page
